import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWorkCenterList() {
  // Use toast
  const toast = useToast()


  // Table Handlers
  const tableColumns = [
    { key: 'id', label: "ID", sortable: true },
    { key: 'name', label: "Name", sortable: true },
    // { key: 'siteID', label: "Site", sortable: true },
    { key: 'siteName', label: "Site", sortable: true },
    // { key: 'productionLocationTypeID', label: "Production Location", sortable: true },
    { key: 'productionLocationTypeName', label: "Production Location Type", sortable: true },
    { key: 'sequenceNo', label: "Sequence No", sortable: true },
    // { key: 'parentID', label: "Parent", sortable: true },
    { key: 'parentName', label: "Parent", sortable: true },
    { key: 'isFinal', label: "Is Final", sortable: true },
    { key: 'isActive', label: "is Active", sortable: true },
    // { key: 'isDelete', label: "is Delete", sortable: true },
    { key: 'actions' },
  ]
  const totalWorkCenters = ref(0)
  const tableItems = ref([])
  const refWorkCenterListTable = ref(null)
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    store
      .dispatch('apps-workcenter/fetchAllWorkCenters', {
      })
      .then(response => {
        response.data = response.data.filter(x => !x.isDelete);
        response.data.forEach(item => {
          item.parentName = '';
          if (item.parentID != 0 && response.data.find(x => x.id == item.parentID) != null) {
            item.parentName = response.data.find(x => x.id == item.parentID).name;
          }
        });
        store
          .dispatch('apps-workcenter/fetchAllSites', {
          })
          .then(response2 => {
            response2.data = response2.data.filter(x => !x.isDelete);
            response.data.forEach(item => {
              item.siteName = '';
              if (item.siteID != 0 && response2.data.find(x => x.id == item.siteID) != null) {
                item.siteName = response2.data.find(x => x.id == item.siteID).name;
              }
            });
            store
              .dispatch('apps-workcenter/fetchAllProductionLocationTypes', {
              })
              .then(response3 => {
                response3.data = response3.data.filter(x => !x.isDelete);
                response.data.forEach(item => {
                  item.productionLocationTypeName = '';
                  if (item.productionLocationTypeID != 0 && response3.data.find(x => x.id == item.productionLocationTypeID) != null) {
                    item.productionLocationTypeName = response3.data.find(x => x.id == item.productionLocationTypeID).name;
                  }
                });
                tableItems.value = response.data;
                totalWorkCenters.value = response.data.length;
              })
              .catch(error => reject(error));
          })
          .catch(error => reject(error));
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching workcenter list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  refetchData();
  return {
    refetchData,
    tableColumns,
    tableItems,
    isSortDirDesc,
    totalWorkCenter: totalWorkCenters,
    refWorkCenterListTable
  }
}
