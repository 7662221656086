<template>
  <b-sidebar id="add-new-work-center-sidebar"
             :visible="isAddNewWorkCenterSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-work-center-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New WorkCenter" : actionType == 1 ? " Update WorkCenter" : "Delete WorkCenter" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext"
                               name="Name"
                               rules="required">
            <b-form-group label="Name"
                          label-for="full-name">
              <b-form-input id="full-name"
                            v-model="currentWorkCenter.name" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="Name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider v-if="actionType != 2"
                               #default="validationContext"
                               name="Sequence No"
                               rules="required">
            <b-form-group label="Sequence No"
                          label-for="sequenceNo">
              <b-form-input id="sequenceNo"
                            v-model="currentWorkCenter.sequenceNo"
                            type="number"
                            :state="getValidationState(validationContext)"
                            trim
                            placeholder="Sequence No" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group v-if="actionType != 2"
                        label="Production Location Type"
                        label-for="production-location-type-id">
            <production-location-type-picker :initialProductionLocationTypes="currentWorkCenter.productionLocationTypeID"
                                             @selectionChanged="onProductionLocationTypeSelectionChanged">
            </production-location-type-picker>
          </b-form-group>

          <b-form-group v-if="actionType != 2"
                        label="Parent WorkCenter"
                        label-for="workcenter-id">
            <work-center-picker :initialWorkCenters="currentWorkCenter.parentID"
                                @selectionChanged="onWorkCenterSelectionChanged"
                                ref="workCenterPickerComponent">
            </work-center-picker>
          </b-form-group>

          <b-form-group v-if="actionType != 2"
                        label="Site"
                        label-for="site-id">
            <site-picker :initialSites="currentWorkCenter.siteID"
                         @selectionChanged="onSiteSelectionChanged">
            </site-picker>
          </b-form-group>

          <validation-provider v-if="actionType != 2"
                               name="Is Final">
            <b-form-group label=""
                          label-for="isFinal">
              <b-form-checkbox
                               class="mt-2"
                               id="isFinal"
                               v-model="currentWorkCenter.isFinal">
                Is Final
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <validation-provider name="Is Active">
            <b-form-group label=""
                          label-for="isActive">
              <b-form-checkbox class="mt-2"
                               id="isActive"
                               :disabled="actionType == 2"
                               v-model="currentWorkCenter.isActive">
                IsActive
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentWorkCenter == null || currentWorkCenter.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="updateworkcenter"
                      v-if="currentWorkCenter != null && currentWorkCenter.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentWorkCenter != null && currentWorkCenter.id != null && actionType == 2"
                      variant="outline-danger"
                      @click="deleteworkcenter">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import WorkCenterPicker from '../components/WorkCenterPicker.vue'
import SitePicker from '../components/SitePicker.vue'
import ProductionLocationTypePicker from '../components/ProductionLocationTypePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    WorkCenterPicker,
    SitePicker,
    ProductionLocationTypePicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewWorkCenterSidebarActive',
    event: 'update:is-add-new-work-center-sidebar-active',
  },
  props: {
    isAddNewWorkCenterSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onWorkCenterSelectionChanged(item) {
      this.currentWorkCenter.parentID = (item != null && item.id != null) ? item.id : 0;
    },
    onSiteSelectionChanged(item) {
      this.currentWorkCenter.siteID = (item != null && item.id != null) ? item.id : 0;
    },
    onProductionLocationTypeSelectionChanged(item) {
      this.currentWorkCenter.productionLocationTypeID = (item != null && item.id != null) ? item.id : 0;
    },
    deleteworkcenter() {
      this.$store.dispatch('apps-workcenter/deleteWorkCenter', this.currentWorkCenter)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-work-center-sidebar-active', false)
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Work Center Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updateworkcenter() {
      if (this.currentWorkCenter.siteID == null || this.currentWorkCenter.siteID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Site Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentWorkCenter.productionLocationTypeID == null || this.currentWorkCenter.productionLocationTypeID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Location Type Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.currentWorkCenter.sequenceNo = this.currentWorkCenter.sequenceNo.toString();
        this.$store.dispatch('apps-workcenter/updateWorkCenter', this.currentWorkCenter)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-work-center-sidebar-active', false)
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Work Center Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    },
    submitForm() {
      if (this.currentWorkCenter.siteID == null || this.currentWorkCenter.siteID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Site Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentWorkCenter.productionLocationTypeID == null || this.currentWorkCenter.productionLocationTypeID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Production Location Type Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.currentWorkCenter.sequenceNo = this.currentWorkCenter.sequenceNo.toString();
        this.$store.dispatch('apps-workcenter/createWorkCenter', this.currentWorkCenter)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-work-center-sidebar-active', false)
            this.$refs.workCenterPickerComponent.fetchWorkCenters();
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create Work Center Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    }
  },

  watch: {
    workcenterdata: {
      handler: function (n, o) {
        if (n == null || n == undefined) {
          this.currentWorkCenter = {
            id: null,
            name: null,
            siteID: null,
            productionLocationTypeID: null,
            parentID: null,
            isFinal: false,
            isActive: false,
          }
        } else {
          this.currentWorkCenter = n.selectedWorkCenter;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      required,
      currentWorkCenter: null
    }
  },
  inject: ['workcenterdata'],
  setup(props, { emit }) {
    const blankWorkCenterData = {
      id: null,
      name: null,
      siteID: null,
      productionLocationTypeID: null,
      parentID: null,
      isFinal: false,
      isActive: false,
    }
    const workCenterData = ref(JSON.parse(JSON.stringify(blankWorkCenterData)))
    const {
      getValidationState,
    } = formValidation(workCenterData)

    return {
      workCenterData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-work-center-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
