import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllWorkCenters(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    fetchAllSites(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Site')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    fetchAllProductionLocationTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/ProductionLocationType')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createWorkCenter(ctx, workCenterData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/WorkCenter/Create', workCenterData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateWorkCenter(ctx, workCenterData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/organization/WorkCenter/Update', workCenterData, { params: { id: workCenterData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteWorkCenter(ctx, workCenterData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/organization/WorkCenter/Delete', { params: { id: workCenterData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
