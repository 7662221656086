<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <v-select v-if="workcenters != null"
                  v-model="selected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="workcenters"
                  class="select-size-lg"
                  single>
          <template #option="{ id, name }">
            <span> {{ name }}</span><br />
            <small class="text-muted"> {{ id }} </small>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created() {
    this.fetchWorkCenters()
  },
  props: {
    initialWorkCenters: []
  },
  methods: {
    fetchWorkCenters() {
      return store
        .dispatch('apps-workcenter/fetchAllWorkCenters', {
        })
        .then(response => {
          response.data = response.data.filter(x => !x.isDelete);
          this.workcenters = response.data;
          this.selected = this.initialWorkCenters != null && response.data.find(x => x.id == this.initialWorkCenters) != null ? response.data.find(x => x.id == this.initialWorkCenters) : null;
        })
        .catch(error => reject(error))
    },
  },
  watch: {
    selected: function (newVal, coldVal) {
      this.$emit("selectionChanged", newVal);
    },
    initialWorkCenters: function (newVal, coldVal) {
      if (this.workcenters.find(x => x.id == newVal) != null)
        this.selected = this.workcenters.find(x => x.id == newVal);
      else
        this.selected = null
    }
  },
  data() {
    return {
      workcenters: null,
      selected: null,
    }
  }
}
</script>